import React from "react";
import { Image } from "react-bootstrap";
import DotLottie from "../cards/dotLottie";

const Loader = () => {
  return (
    <>
    
      <DotLottie/> 

    
    </>
    // <div className="site-loader">
    //   <Image
    //     className="site-logo"
    //     title={`Duncan Taylor`}
    //     alt={`Duncan Taylor`}
    //     src={`https://duncantaylor-api.stageoakum.in/uploads/logo_5f4983c09c.svg`}
    //     fluid
    //   />
    // </div>
  );
};

export default Loader;
