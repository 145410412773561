import React, { useEffect, useState } from "react";
import "../../styles/main.scss";
import Header from "../common/header";
import Footer from "../common/footer";
import { getCookie } from "../../../helper/helper";
import { usePathname } from "next/navigation";
import DrinkResponsiblyModal from "../modal/DrinkResponsiblyModal";
import api from "../../../api";
import { useLocalsListStore } from "@/zustand/localesList";
const NewMainLayout = (props: any) => {
  const { children } = props;
  const path = usePathname();
  const [scrolled, setScrolled] = useState(false);
  const [countryData, setCountryData]: any = useState([]);
  const [templateData, setTemplateData]: any = useState([]);

  const localesList = useLocalsListStore((state: any) => state.localesList);

  const locale = localesList.locale || getCookie("locale") || "en-US";

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array means this effect runs once on mount and cleans up on unmount

  // Add or remove the class based on the 'scrolled' state
  useEffect(() => {
    if (scrolled) {
      document.body.classList.add("Header-scroll");
    } else {
      document.body.classList.remove("Header-scroll");
    }
  }, [scrolled]);

  const getCountryListData = async () => {
    const res = await api
      .getCountryList()
      .then((res: any) => res.data)
      .catch((err: any) => err.data);

    const countryData = res?.slice(1).map((country: any) => {
      return {
        label: country.attributes.name,
        value: country.attributes.locale,
      };
    });
    setCountryData(countryData);
  };

  const getMasterTemplateData = (props: any) => {
    api
      .getMasterTemplate(props)
      .then((res: any) => {
        if (res?.data) {
          setTemplateData(res.data.attributes);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentpath = path?.split("/")[1];
    if (currentpath || locale) {
      getMasterTemplateData(currentpath || locale);
    }
  }, [locale, path]);

  useEffect(() => {
    getCountryListData();
  }, []);

  return (
    <main className="main-section">
      <DrinkResponsiblyModal countryData={countryData} />
      <Header
        countryData={countryData}
        templateData={templateData}
        scrolled={scrolled}
        checkout={props?.checkout}
        toggleCartSidebar={props?.toggleCartSidebar}
      />
      {children}
      <Footer templateData={templateData} />
    </main>
  );
};

export default NewMainLayout;
