import React, { useState } from "react";
import { Image, Modal } from "react-bootstrap";

const SuccessModal = (props:any) => {
    const {show,setShow,modalText}=props;
//   const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  return (
    <Modal
      centered
      dialogClassName={`success-modal`}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header>
        <a className="modal-close" onClick={handleClose}>
          <Image alt="close" fluid src="/svg/modal-close.svg" />
        </a>
      </Modal.Header>
      <Modal.Body>
        <h3 className="section-heading-2 xs">{modalText}</h3>
        {/* <p>Form has been submitted successfully</p> */}
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
