

import Head from "next/head"

const ScoMeta = (props: any) => {
  const BASE_URL = process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL;
  const { meta } = props;

  const defaultTitle = "Duncan Taylor whisky";
  const defaultDescription = "Duncan Taylor";
  const defaultKeywords = "Duncan Taylor";
  const prependTitle = "Duncan Taylor";
  const defaultviewPort = "700px";
  const facebookData: any = meta?.metaSocial ? meta?.metaSocial[0] : "";
  const twitterData: any = meta?.metaSocial ? meta?.metaSocial[1] : "";

  // page title, description and keywords
  const pageTitle = meta && meta?.metaTitle
    ? prependTitle + " "+ meta?.metaTitle
    : defaultTitle;
  const pageDescription = meta?.metaDescription || defaultDescription;
  const keywords = meta?.keywords || defaultKeywords;

  //viewport
  const viewport = meta?.metaViewport || defaultviewPort;

  //structuredData
  const structuredData = meta?.structuredData || [];

  //og:title, description
  const ogTitle = meta?.field_og_title || defaultTitle;
  const ogDescription = meta?.field_og_description || defaultDescription;
  const canonicalURL = meta?.canonicalURL || "";

  // social network
  const facebookSocialNetwork = facebookData?.socialNetwork;
  const twitterSocialNewwork = twitterData?.socialNetwork;
  //Twitter title, description
  const twitterTitle = twitterData?.title || defaultTitle;
  const twitterDescription = twitterData?.description || defaultDescription;

  //Facebook title,description

  const facebookTitle = facebookData?.title || defaultTitle;
  const facebookDescription = facebookData?.description || defaultDescription;

  //Itemprop tags
  const itempropName = meta?.field_og_title || defaultTitle;
  const itempropDescription = meta?.field_og_description || defaultDescription;

  //Images
  const defaultImage = BASE_URL + "/svg/blackbull.svg";

  const twitterImage = twitterData?.image?.data?.attributes?.url || defaultImage;
  const facebookImage =
    facebookData?.image?.data?.attributes?.url || defaultImage;
  const ogImage = meta?.metaImage?.data?.attributes?.url || defaultImage;

  const imageWidth = "1080";
  const imageHeight = "600";

  return (
    <>
      <title key="title">{pageTitle}</title>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge"></meta>
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <link rel="shortcut icon" type="image/png" href="/duncan.png" />
      <meta key="description" name="description" content={pageDescription} />
      <meta key="keywords" name="keywords" content={keywords} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={ogTitle} />
      <meta property="og:url" content={canonicalURL} />
      <meta
        name="og:description"
        property="og:description"
        content={ogDescription}
      />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta name="twitter:card" content={ogDescription} />
      <meta name="twitter:site" content="@DuncanTaylor" />
      <meta name="twitter:creator" content="@DuncanTaylor" />
      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={twitterImage} />
      <meta itemProp="name" content={itempropName} />
      <meta itemProp="description" content={itempropDescription} />
      <meta itemProp="image" content={ogImage} />
    </>
  );
};

export default ScoMeta;
