
import React from "react";
import { DotLottiePlayer } from "@dotlottie/react-player";
import "@dotlottie/react-player/dist/index.css";

const DotLottie = () => {

  const playerStyle = {
    width: "400px",
    height: "400px",
    backgroundColor: "#121212",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="site-loader">
      <DotLottiePlayer
        src="/dotlottie/ls4cjvkb.lottie"
        autoplay
        loop
        style={playerStyle}
      ></DotLottiePlayer>
    </div>
  );
};

export default DotLottie;
