import Client, { Client as ShopifyClient } from "shopify-buy";
import config from "../../apiConfig.ts";

const {
  storefrontAccessToken,
  domain,
  appId,
  storefrontAccessToken_1,
  domain_1,
  appId_1,
} = config;

// const client = Client.buildClient({
//   storefrontAccessToken: storefrontAccessToken,
//   domain: domain,
//   appId: appId,
// });

const shopifyInit = (countryCode) => {
  let clientConfig;

  if (countryCode === "en-US") {
    clientConfig = {
      storefrontAccessToken: storefrontAccessToken_1,
      domain: domain_1,
      appId: appId_1,
    };
  } else {
    clientConfig = {
      storefrontAccessToken: storefrontAccessToken,
      domain: domain,
      appId: appId,
    };
  }

  return Client.buildClient(clientConfig);
};

const getCheckoutId = (countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    if (countryCode) {
      let checkoutIdFromSession = sessionStorage.getItem(
        `${countryCode}-checkoutId`
      );

      if (
        checkoutIdFromSession === undefined ||
        checkoutIdFromSession === null
      ) {
        client.checkout.create().then((checkout) => {
          sessionStorage.setItem(`${countryCode}-checkoutId`, checkout.id);
          resolve(checkout.id);
        });
      } else {
        resolve(checkoutIdFromSession);
      }
    }
  });
};
const getAllProducts = (countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    client.product.fetchAll().then((products) => {
      // Do something with the products
      resolve(products);
    });
  });
};
const getProductDetails = (productId, countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    client.product
      .fetch(productId)
      .then((product) => {
        // Do something with the product

        resolve(product);
      })
      .catch((err) => {
        console.log("err", err);
      });
  });
};
const getCheckoutLineItems = (countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    getCheckoutId(countryCode).then((checkoutId) => {
      client.checkout.fetch(checkoutId).then((res) => {
        // Do something with the checkout

        resolve(res);
      });
    });
  });
};
const setAddVariantToCart = (lineItemsToAdd, countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    getCheckoutId(countryCode).then((checkoutId) => {
      client.checkout
        .addLineItems(checkoutId, lineItemsToAdd)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          sessionStorage.removeItem(`${countryCode}-checkoutId`);
        });
    });
  });
};

const setUpdateQuantityInCart = (lineItemsToUpdate, countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    getCheckoutId(countryCode).then((checkoutId) => {
      client.checkout
        .updateLineItems(checkoutId, lineItemsToUpdate)
        .then((res) => {
          resolve(res);
        });
    });
  });
};

const setRemoveLineItemInCart = (lineItemId, countryCode) => {
  const client = shopifyInit(countryCode);
  return new Promise((resolve, reject) => {
    getCheckoutId(countryCode).then((checkoutId) => {
      client.checkout.removeLineItems(checkoutId, [lineItemId]).then((res) => {
        resolve(res);
      });
    });
  });
};
export {
  shopifyInit,
  getAllProducts,
  getProductDetails,
  getCheckoutId,
  getCheckoutLineItems,
  setAddVariantToCart,
  setUpdateQuantityInCart,
  setRemoveLineItemInCart,
};
