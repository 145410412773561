"use client";
import React, { useEffect, useState } from "react";
import HomePage from "./home/page";
import { getCookie } from "../../helper/helper";
import { useRouter } from "next/navigation";
import api from "../../api";
import NewMainLayout from "@/components/layout/newMainLayout";
import { useLocalsListStore } from "@/zustand/localesList";
import Loader from "@/components/loader/loader";
import BuyNowCart from "@/components/common/BuyNowCart";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";
export default function Home() {
  const router = useRouter();
  const localesList = useLocalsListStore((state: any) => state.localesList);

  const locale = localesList.locale || getCookie("locale") || "en-US";
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = React.useState(false);
  const [countryCode, setCountryCode] = useState<string | null>(null);

  // useEffect(() => {
  //   const ageVerified = getCookie("ageVerified");
  //   if (ageVerified != "true") {
  //     router.push("/age-verification");
  //   }
  // }, []);

  const [homePageData, setTemplateData]: any = useState(null);
  const [meta, setMeta]: any = useState({});

  const getHomePageData = (props: any) => {
    api
      .getHomePage(props)
      .then((res: any) => {
        if (res?.data) {
          setMeta(res?.data?.attributes?.seo);
          setTemplateData(res?.data?.attributes?.blocks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHomePageData(locale);
    setTemplateData(null);
  }, [locale]);

  const toggleCartSidebar = () => {
    if (countryCode) {
      getCheckoutLineItems(countryCode).then((res) => {
        setCheckout({
          ...res,
        });
      });

      setOpenModal(!openModal);
    }
  };

  useEffect(() => {
    const path = window?.location?.pathname;
    const parts = path?.split("/");
    const findCountry = parts[1];
    setCountryCode(findCountry);
    getCheckoutLineItems(findCountry).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  return (
    <main>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      {homePageData ? (
        <NewMainLayout
          checkout={checkout}
          toggleCartSidebar={toggleCartSidebar}
        >
          <HomePage homePageData={homePageData} meta={meta ? meta : {}} />
        </NewMainLayout>
      ) : (
        <Loader />
      )}
    </main>
  );
}
