import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import api from "../../../api";
import { useLocalsListStore } from "@/zustand/localesList";
import { getCookie } from "../../../helper/helper";
import { usePathname } from "next/navigation";
import SuccessModal from "../modal/SuccessModal";

const SignupForFree = () => {
  const path = usePathname();
  const [templateData, setTemplateData]: any = useState([]);
  const localesList = useLocalsListStore((state: any) => state.localesList);
  const locale = localesList.locale || getCookie("locale") || "en-US";
  const getMasterTemplateData = (props: any) => {
    api
      .getMasterTemplate(props)
      .then((res: any) => {
        if (res?.data) {
          setTemplateData(res?.data?.attributes?.newsletter[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const currentpath = path?.split("/")[1];
    if (currentpath || locale) {
      getMasterTemplateData(currentpath || locale);
    }
  }, [locale, path]);
  const [email, setEmail] = useState("");
  const [showEmailError, setShowEmailError] = useState(false);
  const [show, setShow] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (email) {
      try {
        let url =
          process.env.NEXT_PUBLIC_DUNCAN_TAYLOR_API_URL + "/api/subscribers";

        const response = await axios
          .post(url, {
            data: {
              email: `${email}`,
            },
          })
          .then((res: any) => {
            if (res.status == 200) {
              setShow(true);
            }
          });

        setEmail("");
      } catch (error: any) {
        console.log("err", error);
      }
    } else {
      if (!email) {
        setShowEmailError(true);
      }
    }
  };
  return (
    <div className="ninthFoldContainer">
      <Container>
        <Row>
          <Col md={6} xs={12}>
            <div className="headingText">{templateData?.title}</div>
          </Col>
          <Col md={6} xs={12} className="inputfield">
            <div className="inputwrapper">
              <Form onSubmit={handleSubmit}>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formPlaintextEmail"
                >
                  <Col sm="10">
                    <Form.Control
                      type="email"
                      placeholder={templateData?.placeholder}
                      value={email} // Bind value to state variable
                      onChange={(e) => {
                        const enteredValue = e.target.value;
                        setEmail(enteredValue);
                        // Check if the entered value is not empty
                        if (enteredValue.trim().length > 0) {
                          setShowEmailError(false);
                        } else {
                          setShowEmailError(true);
                        }
                      }}
                    />
                  </Col>
                </Form.Group>
                <button type="submit">{templateData?.button}</button>
              </Form>
            </div>
            {showEmailError && (
              <span className="showsignuperror">Enter your email id</span>
            )}
            <SuccessModal
              show={show}
              setShow={setShow}
              modalText={`Thanks for joining our newsletter!`}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignupForFree;
